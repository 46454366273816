export const RGBA_COLOR_DICTIONARY: Record<string, number[]> = {
    aliceblue: [240, 248, 255],
    antiquewhite: [250, 235, 215],
    aqua: [0, 255, 255],
    aquamarine: [127, 255, 212],
    azure: [240, 255, 255],
    beige: [245, 245, 220],
    bisque: [255, 228, 196],
    black: [0, 0, 0],
    blanchedalmond: [255, 235, 205],
    blue: [0, 0, 255],
    blueviolet: [138, 43, 226],
    brown: [165, 42, 42],
    burlywood: [222, 184, 135],
    cadetblue: [95, 158, 160],
    chartreuse: [127, 255, 0],
    chocolate: [210, 105, 30],
    coral: [255, 127, 80],
    cornflowerblue: [100, 149, 237],
    cornsilk: [255, 248, 220],
    crimson: [220, 20, 60],
    cyan: [0, 255, 255],
    darkblue: [0, 0, 139],
    darkcyan: [0, 139, 139],
    darkgoldenrod: [184, 134, 11],
    darkgray: [169, 169, 169],
    darkgreen: [0, 100, 0],
    darkgrey: [169, 169, 169],
    darkkhaki: [189, 183, 107],
    darkmagenta: [139, 0, 139],
    darkolivegreen: [85, 107, 47],
    darkorange: [255, 140, 0],
    darkorchid: [153, 50, 204],
    darkred: [139, 0, 0],
    darksalmon: [233, 150, 122],
    darkseagreen: [143, 188, 143],
    darkslateblue: [72, 61, 139],
    darkslategray: [47, 79, 79],
    darkslategrey: [47, 79, 79],
    darkturquoise: [0, 206, 209],
    darkviolet: [148, 0, 211],
    deeppink: [255, 20, 147],
    deepskyblue: [0, 191, 255],
    dimgray: [105, 105, 105],
    dimgrey: [105, 105, 105],
    dodgerblue: [30, 144, 255],
    firebrick: [178, 34, 34],
    floralwhite: [255, 250, 240],
    forestgreen: [34, 139, 34],
    fuchsia: [255, 0, 255],
    gainsboro: [220, 220, 220],
    ghostwhite: [248, 248, 255],
    gold: [255, 215, 0],
    goldenrod: [218, 165, 32],
    gray: [128, 128, 128],
    grey: [128, 128, 128],
    green: [0, 128, 0],
    greenyellow: [173, 255, 47],
    honeydew: [240, 255, 240],
    hotpink: [255, 105, 180],
    indianred: [205, 92, 92],
    indigo: [75, 0, 130],
    ivory: [255, 255, 240],
    khaki: [240, 230, 140],
    lavender: [230, 230, 250],
    lavenderblush: [255, 240, 245],
    lawngreen: [124, 252, 0],
    lemonchiffon: [255, 250, 205],
    lightblue: [173, 216, 230],
    lightcoral: [240, 128, 128],
    lightcyan: [224, 255, 255],
    lightgoldenrodyellow: [250, 250, 210],
    lightgray: [211, 211, 211],
    lightgreen: [144, 238, 144],
    lightgrey: [211, 211, 211],
    lightpink: [255, 182, 193],
    lightsalmon: [255, 160, 122],
    lightseagreen: [32, 178, 170],
    lightskyblue: [135, 206, 250],
    lightslategray: [119, 136, 153],
    lightslategrey: [119, 136, 153],
    lightsteelblue: [176, 196, 222],
    lightyellow: [255, 255, 224],
    lime: [0, 255, 0],
    limegreen: [50, 205, 50],
    linen: [250, 240, 230],
    magenta: [255, 0, 255],
    maroon: [128, 0, 0],
    mediumaquamarine: [102, 205, 170],
    mediumblue: [0, 0, 205],
    mediumorchid: [186, 85, 211],
    mediumpurple: [147, 112, 219],
    mediumseagreen: [60, 179, 113],
    mediumslateblue: [123, 104, 238],
    mediumspringgreen: [0, 250, 154],
    mediumturquoise: [72, 209, 204],
    mediumvioletred: [199, 21, 133],
    midnightblue: [25, 25, 112],
    mintcream: [245, 255, 250],
    mistyrose: [255, 228, 225],
    moccasin: [255, 228, 181],
    navajowhite: [255, 222, 173],
    navy: [0, 0, 128],
    oldlace: [253, 245, 230],
    olive: [128, 128, 0],
    olivedrab: [107, 142, 35],
    orange: [255, 165, 0],
    orangered: [255, 69, 0],
    orchid: [218, 112, 214],
    palegoldenrod: [238, 232, 170],
    palegreen: [152, 251, 152],
    paleturquoise: [175, 238, 238],
    palevioletred: [219, 112, 147],
    papayawhip: [255, 239, 213],
    peachpuff: [255, 218, 185],
    peru: [205, 133, 63],
    pink: [255, 192, 203],
    plum: [221, 160, 221],
    powderblue: [176, 224, 230],
    purple: [128, 0, 128],
    rebeccapurple: [102, 51, 153],
    red: [255, 0, 0],
    rosybrown: [188, 143, 143],
    royalblue: [65, 105, 225],
    saddlebrown: [139, 69, 19],
    salmon: [250, 128, 114],
    sandybrown: [244, 164, 96],
    seagreen: [46, 139, 87],
    seashell: [255, 245, 238],
    sienna: [160, 82, 45],
    silver: [192, 192, 192],
    skyblue: [135, 206, 235],
    slateblue: [106, 90, 205],
    slategray: [112, 128, 144],
    slategrey: [112, 128, 144],
    snow: [255, 250, 250],
    springgreen: [0, 255, 127],
    steelblue: [70, 130, 180],
    tan: [210, 180, 140],
    teal: [0, 128, 128],
    thistle: [216, 191, 216],
    tomato: [255, 99, 71],
    turquoise: [64, 224, 208],
    violet: [238, 130, 238],
    wheat: [245, 222, 179],
    white: [255, 255, 255],
    whitesmoke: [245, 245, 245],
    yellow: [255, 255, 0],
    yellowgreen: [154, 205, 50]
};

export const COLORS = [
    // red
    '#FFEADB',
    '#FFD7BE',
    '#FFC09E',
    '#FFA47F',
    '#FF8561',
    '#FC6449',
    '#F44336',
    '#E02C24',
    '#C01A17',
    '#8C0E0E',
    // orange
    '#FFECD9',
    '#FFE1BD',
    '#FFD091',
    '#FFB851',
    '#FF9800',
    '#FB8200',
    '#F46900',
    '#E44E00',
    '#C63100',
    '#991900',
    // amber
    '#FFF7E6',
    '#FFE7B9',
    '#FFD88E',
    '#FECB69',
    '#FDBE4C',
    '#FBB035',
    '#F6A024',
    '#EA8C18',
    '#D47110',
    '#B3530B',
    // yellow
    '#FFFFF2',
    '#FFFECC',
    '#FFFCA4',
    '#FFF57D',
    '#FEE759',
    '#FDD63B',
    '#FCC425',
    '#FAB516',
    '#F5AA0E',
    '#E39B0B',
    // lime
    '#F7FFCC',
    '#F0FCA8',
    '#E8F681',
    '#DDEC5B',
    '#CDDC39',
    '#B3C91F',
    '#97B20E',
    '#7B9905',
    '#627F01',
    '#4D6600',
    // green
    '#E8FFE6',
    '#C7FDC2',
    '#A8F9A0',
    '#8EF384',
    '#78EA70',
    '#67DE61',
    '#57CB56',
    '#4CAF50',
    '#1E8F2F',
    '#00661A',
    // teal
    '#E7FCF7',
    '#B9FBEC',
    '#8CF8E0',
    '#64F4D5',
    '#43ECCB',
    '#2AE2C1',
    '#16D1B5',
    '#09B9A3',
    '#009688',
    '#11736E',
    // cyan
    '#EBFFFD',
    '#C2FCF8',
    '#9BF7F1',
    '#78EFEA',
    '#5CE2E3',
    '#46C8D3',
    '#35ADBF',
    '#2791A4',
    '#1D7586',
    '#145866',
    // blue
    '#F3FDFF',
    '#DCFAFF',
    '#C5F6FF',
    '#95EAFF',
    '#4BC9FF',
    '#32B2FB',
    '#2196F3',
    '#0E74E2',
    '#0453C7',
    '#003399',
    // indigo
    '#F4E6FF',
    '#F1DEFF',
    '#E4CFFF',
    '#CAB5FC',
    '#9D8FE9',
    '#6362C9',
    '#3F51B5',
    '#2E3A9D',
    '#21288D',
    '#191980',
    // purple
    '#FFDEF6',
    '#FBDAF3',
    '#F4C7EA',
    '#E9A4DF',
    '#DA76D5',
    '#C047C7',
    '#9C27B0',
    '#8018A0',
    '#680B8F',
    '#550080',
    // pink
    '#FFDEDE',
    '#FFD7D9',
    '#FFC6CA',
    '#FFA9B3',
    '#FD8197',
    '#F5527B',
    '#E91E63',
    '#CD0053',
    '#B00052',
    '#94004C'
];

// Contrast color means that text color should be white for elements with such backgroundColor
// otherwise it should be black
export const IS_CONTRAST_COLOR: Record<string, boolean> = {
    '#FFEADB': false,
    '#FFD7BE': false,
    '#FFC09E': false,
    '#FFA47F': false,
    '#FF8561': false,
    '#FC6449': true,
    '#F44336': true,
    '#E02C24': true,
    '#C01A17': true,
    '#8C0E0E': true,
    '#FFECD9': false,
    '#FFE1BD': false,
    '#FFD091': false,
    '#FFB851': false,
    '#FF9800': false,
    '#FB8200': false,
    '#F46900': true,
    '#E44E00': true,
    '#C63100': true,
    '#991900': true,
    '#FFF7E6': false,
    '#FFE7B9': false,
    '#FFD88E': false,
    '#FECB69': false,
    '#FDBE4C': false,
    '#FBB035': false,
    '#F6A024': false,
    '#EA8C18': true,
    '#D47110': true,
    '#B3530B': true,
    '#FFFFF2': false,
    '#FFFECC': false,
    '#FFFCA4': false,
    '#FFF57D': false,
    '#FEE759': false,
    '#FDD63B': false,
    '#FCC425': false,
    '#FAB516': false,
    '#F5AA0E': true,
    '#E39B0B': true,
    '#F7FFCC': false,
    '#F0FCA8': false,
    '#E8F681': false,
    '#DDEC5B': false,
    '#CDDC39': false,
    '#B3C91F': true,
    '#97B20E': true,
    '#7B9905': true,
    '#627F01': true,
    '#4D6600': true,
    '#E8FFE6': false,
    '#C7FDC2': false,
    '#A8F9A0': false,
    '#8EF384': false,
    '#78EA70': false,
    '#67DE61': true,
    '#57CB56': true,
    '#4CAF50': true,
    '#1E8F2F': true,
    '#00661A': true,
    '#E7FCF7': false,
    '#B9FBEC': false,
    '#8CF8E0': false,
    '#64F4D5': false,
    '#43ECCB': false,
    '#2AE2C1': true,
    '#16D1B5': true,
    '#09B9A3': true,
    '#009688': true,
    '#11736E': true,
    '#EBFFFD': false,
    '#C2FCF8': false,
    '#9BF7F1': false,
    '#78EFEA': false,
    '#5CE2E3': false,
    '#46C8D3': true,
    '#35ADBF': true,
    '#2791A4': true,
    '#1D7586': true,
    '#145866': true,
    '#F3FDFF': false,
    '#DCFAFF': false,
    '#C5F6FF': false,
    '#95EAFF': false,
    '#4BC9FF': false,
    '#32B2FB': true,
    '#2196F3': true,
    '#0E74E2': true,
    '#0453C7': true,
    '#003399': true,
    '#F4E6FF': false,
    '#F1DEFF': false,
    '#E4CFFF': false,
    '#CAB5FC': false,
    '#9D8FE9': false,
    '#6362C9': true,
    '#3F51B5': true,
    '#2E3A9D': true,
    '#21288D': true,
    '#191980': true,
    '#FFDEF6': false,
    '#FBDAF3': false,
    '#F4C7EA': false,
    '#E9A4DF': false,
    '#DA76D5': false,
    '#C047C7': true,
    '#9C27B0': true,
    '#8018A0': true,
    '#680B8F': true,
    '#550080': true,
    '#FFDEDE': false,
    '#FFD7D9': false,
    '#FFC6CA': false,
    '#FFA9B3': false,
    '#FD8197': false,
    '#F5527B': true,
    '#E91E63': true,
    '#CD0053': true,
    '#B00052': true,
    '#94004C': true
};
