import { Dataset } from 'api/typings';

export const datasets: Dataset[] = [
    {
        name: `Juristic Documents 1`,
        count: 1,
        created: '',
        id: 1
    }
];
